import React, { useState } from 'react';
import './Gallery.css';
import { useTranslation } from "react-i18next";

const importAll = (requireContext) => requireContext.keys().map(requireContext);

const images = importAll(require.context('../img/works', false, /\.(png|jpe?g|svg)$/));

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
 const { t } = useTranslation(); // Хук для перевода
  const scrollThumbnails = (direction) => {
    const container = document.querySelector('.thumbnails-container');
    container.scrollBy({ left: direction * 100, behavior: 'smooth' });
  };

  return (
    <div className="gallery">
        <h2>{t("works.h2.title")}</h2>
      <div className="large-image-container">
        <img src={selectedImage} alt="Selected" className="large-image" />
      </div>
      <div className="thumbnails-wrapper">
        <button className="scroll-button left" onClick={() => scrollThumbnails(-1)}>&lt;</button>
        <div className="thumbnails-container">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${image === selectedImage ? 'active' : ''}`}
              onClick={() => setSelectedImage(image)}
            />
          ))}
        </div>
        <button className="scroll-button right" onClick={() => scrollThumbnails(1)}>&gt;</button>
      </div>
    </div>
  );
};

export default Gallery;
