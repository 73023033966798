import React from "react";
import "./ContactsModule.css";
import { useTranslation } from "react-i18next";

const ContactsModule = () => {
  const { t } = useTranslation();
  return (
    <div className="contacts-container">
      <h2 className="contacts-title">{t("contacts.title")}</h2>
      <p className="contacts-email">Email: info@format3d.ru</p>
      <p className="contacts-phone">{t("contacts.telephone")}</p>
      <div className="contacts-buttons">
        <a
          href="https://t.me/TheRealSmile"
          className="button telegram-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Telegram
        </a>
        <a
          href="https://wa.me/79100833600"
          className="button whatsapp-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          WhatsApp
        </a>
        <a
          href="https://vk.com/v_977"
          className="button vk-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          ВКонтакте
        </a>
      </div>
    </div>
  );
};

export default ContactsModule;
