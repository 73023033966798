import React from 'react';
import './HowWeWork.css';
import { useTranslation } from "react-i18next";

const HowWeWork = () => {
    const { t } = useTranslation(); // Хук для перевода
  
    const steps = [
      { title: t("cardblock.steps.1.title"), description: t("cardblock.steps.1.description") },
      { title: t("cardblock.steps.2.title"), description: t("cardblock.steps.2.description") },
      { title: t("cardblock.steps.3.title"), description: t("cardblock.steps.3.description") },
      { title: t("cardblock.steps.4.title"), description: t("cardblock.steps.4.description") },
      { title: t("cardblock.steps.5.title"), description: t("cardblock.steps.5.description") },
    ];
  return (
    <div className="how-we-work">
      <h2>{t("cardblock.h2.title")}</h2>
      <div className="steps">
        {steps.map((step, index) => (
          <div key={index} className="step">
            <div className="circle">{index + 1}</div>
            <div className="content">
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
           
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowWeWork;
