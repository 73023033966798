import React from "react";
import "./CardBlock.css";
import tbotGif from "../img/4.jpg";
import webappGif from "../img/1.jpg";
import crosLogo from "../img/3.jpg";
import langLogo from "../img/2.jpg";
import autologo from "../img/6.jpg";
import gameGif from "../img/5.jpg";
import img7 from "../img/7.jpg";
import img8 from "../img/8.jpg";
import { useTranslation } from "react-i18next";

const CardBlock = ({ onCardClick }) => {
  const { t } = useTranslation();

  return (
    <div className="card-container">
      <div className="card-block">
        <div className="card">
          <div className="card-content">
            <p className="card-subtitle">{t("cardblock.web.subtitle")}</p>
            <h2 className="card-title">{t("cardblock.web.title")}</h2>
            <p className="card-text">{t("cardblock.web.text")}</p>
            <button className="card-button" onClick={() => onCardClick()}>
              {t("cardblock.button.more")}
            </button>
          </div>
          <div className="card-image">
            <img src={webappGif} alt="Card Illustration" />
          </div>
        </div>

        <div className="card">
          <div className="card-content">
            <p className="card-subtitle">{t("cardblock.localization.subtitle")}</p>
            <h2 className="card-title">{t("cardblock.localization.title")}</h2>
            <p className="card-text">{t("cardblock.localization.text")}</p>
            <button className="card-button" onClick={() => onCardClick()}>
              {t("cardblock.button.more")}
            </button>
          </div>
          <div className="card-image">
            <img src={langLogo} alt="Card Illustration" />
          </div>
        </div>
      </div>

      <div className="card-block">
        <div className="card">
          <div className="card-content">
            <p className="card-subtitle">{t("cardblock.apps.subtitle")}</p>
            <h2 className="card-title">{t("cardblock.apps.title")}</h2>
            <p className="card-text">{t("cardblock.apps.text")}</p>
            <button className="card-button" onClick={() => onCardClick()}>
              {t("cardblock.button.more")}
            </button>
          </div>
          <div className="card-image">
            <img src={crosLogo} alt="Card Illustration" />
          </div>
        </div>

        <div className="card">
          <div className="card-content">
            <p className="card-subtitle">{t("cardblock.bots.subtitle")}</p>
            <h2 className="card-title">{t("cardblock.bots.title")}</h2>
            <p className="card-text">{t("cardblock.bots.text")}</p>
            <button className="card-button" onClick={() => onCardClick()}>
              {t("cardblock.button.more")}
            </button>
          </div>
          <div className="card-image">
            <img src={tbotGif} alt="Card Illustration" />
          </div>
        </div>
      </div>

      <div className="card-block">
        <div className="card">
          <div className="card-content">
            <p className="card-subtitle">{t("cardblock.zap.subtitle")}</p>
            <h2 className="card-title">{t("cardblock.zap.title")}</h2>
            <p className="card-text">{t("cardblock.zap.text")}</p>
            <button className="card-button" onClick={() => onCardClick()}>
              {t("cardblock.button.more")}
            </button>
          </div>
          <div className="card-image">
            <img src={gameGif} alt="Card Illustration" />
          </div>
        </div>

        <div className="card">
          <div className="card-content">
            <p className="card-subtitle">{t("cardblock.spec.subtitle")}</p>
            <h2 className="card-title">{t("cardblock.spec.title")}</h2>
            <p className="card-text">{t("cardblock.spec.text")}</p>
            <button className="card-button" onClick={() => onCardClick()}>
              {t("cardblock.button.more")}
            </button>
          </div>
          <div className="card-image">
            <img src={autologo} alt="Card Illustration" />
          </div>
        </div>
      </div>
      <div className="card-block">
        <div className="card">
          <div className="card-content">
            <p className="card-subtitle">{t("cardblock.games.subtitle")}</p>
            <h2 className="card-title">{t("cardblock.games.title")}</h2>
            <p className="card-text">{t("cardblock.games.text")}</p>
            <button className="card-button" onClick={() => onCardClick()}>
              {t("cardblock.button.more")}
            </button>
          </div>
          <div className="card-image">
            <img src={img7} alt="Card Illustration" />
          </div>
        </div>

        <div className="card">
          <div className="card-content">
            <p className="card-subtitle">{t("cardblock.services.subtitle")}</p>
            <h2 className="card-title">{t("cardblock.services.title")}</h2>
            <p className="card-text">{t("cardblock.services.text")}</p>
            <button className="card-button" onClick={() => onCardClick()}>
              {t("cardblock.button.more")}
            </button>
          </div>
          <div className="card-image">
            <img src={img8} alt="Card Illustration" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBlock;
