import React from 'react';
import './Header.css';
import DropdownMenu from "./DropdownMenu";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation(); // Подключаем хук для перевода

  return (
    <header className="header">
      <h2>FORMAT3D</h2>
      <p className="textBlack">{t('header')}</p> {/* Используем ключ для текста */}
      <nav>
        <DropdownMenu /> {/* Меню выбора языка */}
      </nav>
    </header>
  );
};

export default Header;
