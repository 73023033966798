import React from 'react';
import './Footer.css';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (

  <footer className="footer">
    <p>{t("footer.title")}</p>
    <p>{t("footer.copiraite")}</p>
    
  </footer>);
};

export default Footer;